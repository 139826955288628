<template>
  <el-table border :data="tableData" tooltip-effect="dark" empty-text="请上传文件" height="string">
    <template name="tableColumn">
      <el-table-column prop="attachmentName" label="文件名称" align="center">
        <template slot-scope="scope">
          <template v-if="scope.row.loading">
            <i class="el-icon-loading" style="color: #f00"></i>正在上传
          </template>
          <template v-else>
            <a
              target="_blank"
              style="text-decoration: revert; color: #409eff"
              :href="`${filepath}${scope.row.attachmentPath}`"
              >{{ scope.row.attachmentName }}</a
            >
          </template>
        </template>
      </el-table-column>
      <!-- <el-form-item label="文件类型" v-if="attachmentType" prop="attachmentType" ref="attachmentType"> -->
      <!-- <template slot-scope="scope"> -->
      <!-- <Dictionary  v-model="scope.row.attachmentType" code="RYFJLX" placeholder="请选择类型" /> -->
      <!-- </template> -->
      <!-- </el-form-item> -->

      <el-table-column prop="remark" label="备注" align="center">
        <template slot-scope="scope">
          <el-input
            :disabled="disabled"
            type="textarea"
            @input="$forceUpdate()"
            v-model="scope.row.remark"
            :autosize="{ minRows: 2, maxRows: 2 }">
          </el-input>
        </template>
      </el-table-column>
    </template>
    <el-table-column align="center" width="100" v-if="!disabled">
      <template slot="header" slot-scope="scope">
        <Upload :fileCodeParam="fileCode" @upload="upload"></Upload>
      </template>
      <template slot-scope="scope">
        <el-button type="text" size="small" @click="del(scope)">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  components: {
    Upload: () => import('./Upload.vue'),
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      // type: Array,
      default: function () {
        return []
      },
    },
    fileCode: {
      type: String,
      default: 'default',
    },
    staffId: {
      // type:Number,
      default: '',
    },
    type: {
      type: String,
      default: 'fileList',
    },
    attachmentType: {
      //控制附件类型显示与否
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filepath: process.env.VUE_APP_FILEPATH,
      tableData: [],
    }
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  watch: {
    value: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.tableData = newVal
      },
    },
  },
  mounted() {},
  methods: {
    upload(r) {
      r.some(res => {
        this.tableData.push(
          res.combination({
            fileCode: this.fileCode,
            staffId: this.staffId,
            remark: '',
          })
        )
      })
      this.$emit('change', this.tableData)
    },
    del(scope) {
      // if(scope.row.id){
      //   if(this.type == 'user'){
      //     this.$api.user.deleteUserAttachment(scope.row.id).then(res => {
      //         this.tableData.splice(scope.$index,1);
      //         this.$emit('change', this.tableData);
      //     }).catch(err => {});
      //   }

      // }else {
      this.tableData.splice(scope.$index, 1)
      this.$emit('change', this.tableData)
      // };
    },
  },
}
</script>
<style lang="scss" scoped></style>
